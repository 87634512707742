@font-face {
  font-family: 'Clash Display Bold';
  src: url('./assets/fonts/ClashDisplay-Bold.otf');
}

@font-face {
  font-family: 'Clash Display Extralight';
  src: url('./assets/fonts/ClashDisplay-Extralight.otf');
}

@font-face {
  font-family: 'Clash Display Light';
  src: url('./assets/fonts/ClashDisplay-Light.otf');
}

@font-face {
  font-family: 'Clash Display Medium';
  src: url('./assets/fonts/ClashDisplay-Medium.otf');
}

@font-face {
  font-family: 'Clash Display Regular';
  src: url('./assets/fonts/ClashDisplay-Regular.otf');
}

@font-face {
  font-family: 'Clash Display Semibold';
  src: url('./assets/fonts/ClashDisplay-Semibold.otf');
}